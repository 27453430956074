import { useEffect, useState } from "react";

import { EpicToaster } from "~/components/toaster";
import { type Toast } from "~/lib/toast.server";

type NotificationProps = {
  endpoint: string;
  sessionId: string | null;
};

export const Notifications = ({ sessionId, endpoint }: NotificationProps) => {
  const [notification, setNotification] = useState<Toast | null>(null);

  useEffect(() => {
    if (sessionId && endpoint) {
      const ws = new WebSocket(`${endpoint}?sessionId=${sessionId}`);

      ws.onmessage = (evt) => {
        console.debug("WS received", evt);
        setNotification(JSON.parse(evt.data).notification);
      };

      ws.onerror = (err) => {
        console.warn("WS error", err);
      };

      return () => {
        ws.close();
      };
    }
  }, [sessionId, endpoint]);

  // TODO: Instead may want to use sonner ToastT component for more control
  return <EpicToaster toast={notification} />;
};
