import { useEffect } from "react";

import { useLocation } from "@remix-run/react";
import { load, trackPageview } from "fathom-client";

const Fathom = () => {
  const location = useLocation();

  useEffect(() => {
    load("DCUPUXPE", {
      includedDomains: ["candidatestream.io"],
    });
  }, []);

  useEffect(() => {
    trackPageview();
  }, [location.pathname, location.search]);

  return null;
};

export default Fathom;
