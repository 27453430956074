import { json } from "@remix-run/node";
import type {
  ActionFunctionArgs,
  HeadersFunction,
  LinksFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { Resource } from "sst";

import { Notifications } from "./components/app/Notifications";
import { Confetti } from "./components/confetti";
import { GeneralErrorBoundary } from "./components/error-boundary";
import Fathom from "./components/fathom";
import { EpicProgress } from "./components/progress-bar";
import { EpicToaster } from "./components/toaster";
import { authenticator, getSessionId, getUserId } from "./lib/auth.server";
import { ClientHintCheck, getHints } from "./lib/client-hints";
import { getConfetti } from "./lib/confetti.server";
import { csrf } from "./lib/csrf.server";
import { getEnv } from "./lib/env.server";
import { honeypot } from "./lib/honeypot.server";
import { combineHeaders, getDomainUrl } from "./lib/misc";
import { useNonce } from "./lib/nonce-provider";
import { getToast } from "./lib/toast.server";
import { AccountsService } from "./models/accounts/accountsService.server";
import { href as iconsHref } from "~/components/ui/icon";
import { makeTimings, time } from "~/lib/timing.server";
import fontStyleSheetUrl from "~/styles/font.css?url";
import tailwindStyleSheetUrl from "~/styles/tailwind.css?url";

export const links: LinksFunction = () => [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: "preload", href: iconsHref, as: "image" },
  // Preload CSS as a resource to avoid render blocking
  { rel: "preload", href: fontStyleSheetUrl, as: "style" },
  { rel: "preload", href: tailwindStyleSheetUrl, as: "style" },

  //These should match the css preloads above to avoid css as render blocking resource
  { rel: "stylesheet", href: fontStyleSheetUrl },
  { rel: "stylesheet", href: tailwindStyleSheetUrl },
];

export async function loader({ request }: ActionFunctionArgs) {
  const timings = makeTimings("root loader");
  const userId = await time(() => getUserId(request), {
    timings,
    type: "getUserId",
    desc: "getUserId in root",
  });

  const userFetched = userId
    ? await time(
        () => AccountsService.entities.users.query.user({ userId }).go(),
        {
          timings,
          type: "find user",
          desc: "find user in root",
        },
      )
    : null;

  let user;
  if (userFetched?.data.length) {
    user = userFetched.data[0];
  }
  if (userId && !user) {
    console.info("something weird happened");
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await authenticator.logout(request, { redirectTo: "/" });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const { confettiId, headers: confettiHeaders } = getConfetti(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      user,
      sessionId: await getSessionId(request),
      wsEndpoint: Resource.AppSocket.url,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          // theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      confettiId,
      honeyProps,
      csrfToken,
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        toastHeaders,
        confettiHeaders,
        csrfCookieHeader ? { "set-cookie": csrfCookieHeader } : null,
      ),
    },
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };

  return headers;
};

function Document({
  children,
  nonce,
  theme = "light",
  env = {},
}: {
  children: React.ReactNode;
  nonce: string;
  theme?: "light" | "dark"; // Theme;
  env?: Record<string, string>;
}) {
  return (
    <html
      lang="en"
      className={`${theme} h-full overflow-x-hidden scroll-smooth`}
    >
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="bg-background text-foreground h-full bg-gray-100">
        <Fathom />
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <Document nonce={nonce} env={data.ENV}>
      <Outlet />
      <Confetti id={data.confettiId} />
      <EpicToaster toast={data.toast} />
      {!!data.sessionId && (
        <Notifications sessionId={data.sessionId} endpoint={data.wsEndpoint} />
      )}
      <EpicProgress />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
      <HoneypotProvider {...data.honeyProps}>
        <App />
      </HoneypotProvider>
    </AuthenticityTokenProvider>
  );
}

export default AppWithProviders;

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
